import { Helmet } from "react-helmet";

import globaldata from "../data/global.json";
import logo from "../assets/usfm-logo-cropped.webp";
import CButton from "../components/cbutton";

export default function ServicesPage(props) {
  const title = props.data.name + " - " + globaldata.shortdomain;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={title} />
        <meta property="og:description" content={props.data.desc} />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={title} />
      </Helmet>
      <h1 className="text-center font-medium text-2xl my-5">
          {props.data.metadesc}
        </h1>
      <img
          className="h-72	bg-center m-auto "
          src={require(`../assets/${props.data.image}`)}
          alt={globaldata.desc}
        />

      <section className=" lg:text-center border mt-2">

        <div className="mb-5 p-3"><div className="text-2xl font-bold m-2 text-center">{props.data.desctitle1}</div><p className="p-1">{props.data.desc}</p></div>
      </section>
      <section className="m-auto text-left lg:text-center border mt-2">
        <div className="  border-blue-200 p-3"><div className="text-2xl font-bold m-2 text-center">{props.data.desctitle2}</div><p className="p-1">{props.data.desc2}</p></div>
      </section>
      <div className="m-2 text-center ">
      <CButton to={"/contact"} />
      </div>
    </>
  );
}
