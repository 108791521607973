import facebook from "../../assets/usfm-facebook.webp";
import pinterest from "../../assets/usfm-pinterest.webp";
import twitter from "../../assets/usfm-twitter.webp";
import instagram from "../../assets/usfm-instagram.webp";
import github from "../../assets/usfm-github.webp";
import yelp from "../../assets/usfm-yelp.webp";
import ebay from "../../assets/ebay.png";


export default function Footer() {
  return (
    <section className="bg-slate-200/50 w-full p-2 m-auto text-center">
      <div className="flex flex-row justify-center items-center">
        <a
          href="https://www.facebook.com/unitedstatesfamilymarketing"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="United States Family Marketing Social "
            className="transition hover:scale-105 w-8 h-8 m-1"
            src={facebook}
          />
        </a>
        <a
          href="https://www.pinterest.com/unitedstatesfamilymarketing/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="United States Family Marketing Social "
            className="transition hover:scale-105 w-8 h-8 m-1"
            src={pinterest}
          />
        </a>
        <a
          href="https://twitter.com/familywebdesign"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="United States Family Marketing Social "
            className="transition hover:scale-105 w-8 h-8 m-1"
            src={twitter}
          />
        </a>
        <a
          href="https://www.instagram.com/usfamilymarketing2022/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="United States Family Marketing Social "
            className="transition hover:scale-105 w-8 h-8 m-1"
            src={instagram}
          />
        </a>
        <a
          href="https://github.com/usfm-admin"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="United States Family Marketing Social "
            className="transition hover:scale-105 w-8 h-8 m-1"
            src={github}
          />
        </a>
        <a
          href="https://www.yelp.com/biz/united-states-family-marketing-mission-viejo"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="United States Family Marketing Social "
            className="transition hover:scale-105 w-8 h-8 m-1"
            src={yelp}
          />
        </a>
        <a
          href="https://www.ebay.com/usr/ausro2027"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="United States Family Marketing Social "
            className="transition hover:scale-105 w-8 h-8 m-1"
            src={ebay}
          />
        </a>
        <a
          href="https://business.fiverr.com/users/uswebservices"
          target="_blank"
          rel="noreferrer"
        >
     
          <svg width="89" height="27" viewBox="0 0 89 27" fill="none" xmlns="http://www.w3.org/2000/svg"><g fill="#404145"><path d="m81.6 13.1h-3.1c-2 0-3.1 1.5-3.1 4.1v9.3h-6v-13.4h-2.5c-2 0-3.1 1.5-3.1 4.1v9.3h-6v-18.4h6v2.8c1-2.2 2.3-2.8 4.3-2.8h7.3v2.8c1-2.2 2.3-2.8 4.3-2.8h2zm-25.2 5.6h-12.4c.3 2.1 1.6 3.2 3.7 3.2 1.6 0 2.7-.7 3.1-1.8l5.3 1.5c-1.3 3.2-4.5 5.1-8.4 5.1-6.5 0-9.5-5.1-9.5-9.5 0-4.3 2.6-9.4 9.1-9.4 6.9 0 9.2 5.2 9.2 9.1 0 .9 0 1.4-.1 1.8zm-5.7-3.5c-.1-1.6-1.3-3-3.3-3-1.9 0-3 .8-3.4 3zm-22.9 11.3h5.2l6.6-18.3h-6l-3.2 10.7-3.2-10.8h-6zm-24.4 0h5.9v-13.4h5.7v13.4h5.9v-18.4h-11.6v-1.1c0-1.2.9-2 2.2-2h3.5v-5h-4.4c-4.3 0-7.2 2.7-7.2 6.6v1.5h-3.4v5h3.4z"></path></g><g fill="#1dbf73"><path d="m85.3 27c2 0 3.7-1.7 3.7-3.7s-1.7-3.7-3.7-3.7-3.7 1.7-3.7 3.7 1.7 3.7 3.7 3.7z"></path></g></svg>
        </a>
      </div>
      <div className="">
        Website Design by{" "}
        <a
          className="text-blue-500 hover:text-blue-800"
          href="https://familymarketing.us"
        >
          United States Family Marketing
        </a>
      </div>
    </section>
  );
}
